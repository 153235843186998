import React from "react";
import {
  MainWrapper,
  CalendarArea,
  CalendarBox,
} from "../styles/Calendar.Styles";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const MainCalendar = () => {
  return (
    <MainWrapper>
      <Navbar />
      <CalendarArea>
        <CalendarBox> 
          {/* <iframe
            src="https://calendar.google.com/calendar/embed?src=c20c6eu3v5ln6kt59uotu91794%40group.calendar.google.com&ctz=America%2FNew_York"
            style={{ }}
            width="100%"
            height="100%"
            frameBorder="0"
            scrolling="no"
          ></iframe> */} 
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23efe2ba&ctz=America%2FNew_York&src=YzIwYzZldTN2NWxuNmt0NTl1b3R1OTE3OTRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23616161"
            style={{}}
            width="100%"
            height="100%"
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </CalendarBox>
      </CalendarArea>
      <Footer />
    </MainWrapper>
  );
};

export default MainCalendar;


