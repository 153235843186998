import styled from "styled-components";

export const MainWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background: #FFFFFF;

    @media (max-width: 375px){
        height: auto;
    }
`

export const CardsWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 80%;
    width: 90%;


`
export const ZelleArea = styled.div`
    display: flex;
    flex-direction: column;
    height: 80%;
    width: 80%;
    align-items: center;
    justify-content: space-evenly;

    @media(max-width: 750px){
        display: flex;
        height: 95%;
        width: 95%;
    }

    

`

export const PaypalArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 80%;
    width: 30%;
`

export const StripeArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 80%;
    width: 30%;
    
`
export const NumbersArea = styled.div`
    display: flex;
    height: 12%;
    width: 90%;
    align-items: center;
    justify-content: center;

`

export const Numbers = styled.h3`
    display: flex;
    color: gray;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 15%;
    border: 1px solid #EFE2BA;
    border-radius: 100%;
    font-size: 2em;
    font-weight: bold;


`

export const HeadingZelle = styled.div`
    display: flex;
    font-weight: bold;
    font-size: 2em;
    height: 20%;
    width: 97%;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.05em;
    border-bottom: 1px solid lightgray;
    @media (max-width: 750px) {
        display: flex;
        font-weight: bold;
        font-size: 1.5em;
    }

`
export const ZelleTextArea = styled.div`
    display: flex;
    height: 20%;
    width: 97%;
    color: gray;
    font-size: 1em;
    // align-items: center;
    justify-content: center;
    letter-spacing: 0.1em;

    @media (max-width: 750px) {
        display: flex;
        height: 70%;
        padding: 3%;
    }

`

export const ZelleScannablePic = styled.img` 
    display: flex;
    height: 60%;
    // width: 70%;

    @media (max-width: 750px) {
        display: flex;
        align-items: center;   // from here bellow all code must be checked when scannable will be uploaded
        justify-content: center;
        width: 70%;
        height: 80%;
    }


`


export const HeadingPayPal = styled.div`
    display: flex;
    font-wieght: bold;
    font-size: 1.19em;
    height: 12%;
    width: 99%;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid lightgray;

`

export const PayPalTextArea = styled.div`
    display: flex;
    height: 20%;
    width: 97%;
    color: gray;
    font-size: 0.8em;
    // align-items: center;
    justify-content: center;
    letter-spacing: 0.1em;

`

export const PayPalLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 50%;
    cursor: pointer;

`

export const HeadingStripe = styled.div`
    display: flex;
    font-wieght: bold;
    font-size: 1.19em;
    height: 12%;
    width: 99%;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid lightgray;

`

export const StripeTextArea = styled.div`
    display: flex;
    height: 20%;
    width: 97%;
    color: gray;
    font-size: 0.8em;
    // align-items: center;
    justify-content: center;
    letter-spacing: 0.1em;

`

export const StripeLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 50%;
    cursor: pointer;

`