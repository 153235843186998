import styled from "styled-components";

export const NavWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 20%;
    width: 100%;

    @media(max-width: 375px) {
        height: 20vh;
    }

`
export const NavLinksArea = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 50%;
    width: 100%;

`
export const UniNavDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 30%;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;

    :hover{
        font-size: 1.2em;
        border-bottom: 1px solid white
    }

    animation: UninavDiv 4s ease 0s 1 normal forwards;
    @keyframes UninavDiv {
        0% {
            opacity: 0;
        }
    
        100% {
            opacity: 1;
        }
    }
    @media (max-width: 375px){
        font-size: 0.8em;
    }
  
    }


`

