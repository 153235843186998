import ImageGallery from 'react-image-gallery';
import image1 from '../assets/mtredi1.jpeg'
import image2 from '../assets/mtredi.png'
import image3 from '../assets/behind.png'
import image4 from '../assets/everybody.jpeg'
import image5 from '../assets/5.jpeg'
import image6 from '../assets/6.jpeg'
import image7 from '../assets/7.jpeg'
import image8 from '../assets/8.jpeg'
import image9 from '../assets/9.jpeg'
import image10 from '../assets/10.jpeg'
import image11 from '../assets/11.jpeg'
import image12 from '../assets/12.jpeg'
import image14 from '../assets/14.jpeg'
import image15 from '../assets/15.jpeg'
import image16 from '../assets/16.jpeg'
import image17 from '../assets/17.jpeg'
import image18 from '../assets/18.jpeg'
import image19 from '../assets/19.jpeg'
import image20 from '../assets/20.jpeg'
import image21 from '../assets/21.jpeg'
import image23 from '../assets/23.jpeg'
import image24 from '../assets/24.jpeg'
import image25 from '../assets/25.jpeg'
import image26 from '../assets/26.jpeg'
import image27 from '../assets/27.jpeg'
import image28 from '../assets/28.jpeg'
import image29 from '../assets/29.jpeg'
import image30 from '../assets/30.jpeg'
import image31 from '../assets/31.jpeg'
import image32 from '../assets/32.jpeg'
import image33 from '../assets/33.jpeg'
import image34 from '../assets/34.jpeg'
import image35 from '../assets/35.jpeg'
import image36 from '../assets/36.jpeg'
import image37 from '../assets/37.jpeg'
import image38 from '../assets/38.jpeg'
import image39 from '../assets/39.jpeg'
import image40 from '../assets/40.jpeg'




const images = [
    {
      original: image1,
      thumbnail: image1,
    },
    {
      original: image2,
      thumbnail: image2,
    },
    {
      original: image3,
      thumbnail: image3,
    },
    {
        original: image4,
        thumbnail: image4,
      },
      {
        original: image5,
        thumbnail: image5,
      },
      {
        original: image6,
        thumbnail: image6,
      },
      {
        original: image7,
        thumbnail: image7,
      },
      {
        original: image8,
        thumbnail: image8,
      },
      {
        original: image9,
        thumbnail: image9,
      },
      {
        original: image10,
        thumbnail: image10,
      },
      {
        original: image11,
        thumbnail: image11,
      },
      {
        original: image12,
        thumbnail: image12,
      },
      {
        original: image14,
        thumbnail: image14,
      },
      {
        original: image14,
        thumbnail: image14,
      },
      {
        original: image15,
        thumbnail: image15,
      },
      {
        original: image16,
        thumbnail: image16,
      },
      {
        original: image17,
        thumbnail: image17,
      },
      {
        original: image18,
        thumbnail: image18,
      },
      {
        original: image19,
        thumbnail: image19,
      },
      {
        original: image20,
        thumbnail: image20,
      },
      {
        original: image21,
        thumbnail: image21,
      },
      {
        original: image23,
        thumbnail: image23,
      },
      {
        original: image24,
        thumbnail: image24,
      },
      {
        original: image25,
        thumbnail: image25,
      },
      {
        original: image26,
        thumbnail: image26,
      },
      {
        original: image27,
        thumbnail: image27,
      },
      {
        original: image28,
        thumbnail: image28,
      },
      {
        original: image29,
        thumbnail: image29,
      },
      {
        original: image30,
        thumbnail: image30,
      },
      {
        original: image31,
        thumbnail: image31,
      },
      {
        original: image32,
        thumbnail: image32,
      },
      {
        original: image33,
        thumbnail: image33,
      },
      {
        original: image34,
        thumbnail: image34,
      },
      {
        original: image35,
        thumbnail: image35,
      },
      {
        original: image36,
        thumbnail: image36,
      },
      {
        original: image37,
        thumbnail: image37,
      },
      {
        original: image38,
        thumbnail: image38,
      },
      {
        original: image39,
        thumbnail: image39,
      },
      {
        original: image40,
        thumbnail: image40,
      },
      
      
      
  ];

  export default images