import React from "react";
import { NavWrapper, UniNavDiv, NavLinksArea } from "../styles/HamLinks.Styles";
import { Link } from "react-router-dom";

const HamLinks = () => {
  return (
    <NavWrapper>
        <NavLinksArea>
      <UniNavDiv>
        <Link to="/AboutUs" style={{ textDecoration: "none", color: "black" }}>
          ჩვენს შესახებ{" "}
        </Link>
      </UniNavDiv>
      <UniNavDiv>
        <Link to="/Gallery" style={{ textDecoration: "none", color: "black" }}>
          გალერეა
        </Link>
      </UniNavDiv>
      <UniNavDiv>
        <Link to="/Calendar" style={{ textDecoration: "none", color: "black" }}>
          კალენდარი
        </Link>
      </UniNavDiv>
      </NavLinksArea>
      <NavLinksArea>
      <UniNavDiv>
        <Link to="/News" style={{ textDecoration: "none", color: "black" }}>
          სიახლები
        </Link>
      </UniNavDiv>
      <UniNavDiv>
        <Link to="/Donate" style={{ textDecoration: "none", color: "black" }}>
          შემოწირულობა
        </Link>
      </UniNavDiv>
      <UniNavDiv>
        <Link to="/Contact" style={{ textDecoration: "none", color: "black" }}>
          კონტაქტი
        </Link>
      </UniNavDiv>
      </NavLinksArea>
    </NavWrapper>
  );
};

export default HamLinks;
