import React from "react";
import {
  MainWrapper,
  NewsArea,
  NewsFeed,
  DateArea,
  Heading,
  NewsImage,
  TextArea,
} from "../styles/News.Styles";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import newsData from "../data/newsData";


const timestamp = Date.now();

const News = () => {
  return (
    <MainWrapper>
      <Navbar />
      <NewsArea>
        {newsData.map((item, index) => (
          <NewsFeed key={index}>
            {item.newsTime && <DateArea>{item.newsTime}</DateArea>}
            {item.heading && <Heading>{item.heading}</Heading>}
            {item.img && <NewsImage src={item.img} size={100} />}
            {item.text && <TextArea>{item.text}</TextArea>}
          </NewsFeed>
        ))}
      </NewsArea>
      <Footer />
    </MainWrapper>
  );
};

export default News;
