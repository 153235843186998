import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { NavWrapper, UniNavDiv, CrossArea } from "../styles/Navbar.Styles";
import HamNavBar from "./HamNavbar";
import Cross from "../assets/cross.png";

const Navbar = () => {
  const useWindowSize = () => {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const resizeHandler = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", resizeHandler);
      return () => {
        window.removeEventListener("resize", resizeHandler);
      };
    }, []);
    return size;
  };
  const [height, width] = useWindowSize();


  // height < 1000 ? console.log('its less than 1000') : console.log('more than 1000')

  return (
    width < 750 ? <HamNavBar /> :
    <NavWrapper>
      <UniNavDiv>
        <Link to="/AboutUs" style={{ textDecoration: "none", color: "black" }}>
          ჩვენს შესახებ{" "}
        </Link>
      </UniNavDiv>
      <UniNavDiv>
        <Link to="/Gallery" style={{ textDecoration: "none", color: "black" }}>
          გალერეა
        </Link>
      </UniNavDiv>
      <UniNavDiv>
        <Link to="/Calendar" style={{ textDecoration: "none", color: "black" }}>
          კალენდარი
        </Link>
      </UniNavDiv>
      <CrossArea>
        <img src={Cross} height="150%" alt='cross image'/>
      </CrossArea>
      <UniNavDiv>
        <Link to="/News" style={{ textDecoration: "none", color: "black" }}>
          სიახლები
        </Link>
      </UniNavDiv>
      <UniNavDiv>
        <Link to="/Donate" style={{ textDecoration: "none", color: "black" }}>
          შემოწირულობა
        </Link>
      </UniNavDiv>
      <UniNavDiv>
        <Link to="/Contact" style={{ textDecoration: "none", color: "black" }}>
          კონტაქტი
        </Link>
      </UniNavDiv>
    </NavWrapper>
  );
};

export default Navbar;
