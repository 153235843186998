import React from "react-dom";
import {
  MainWrapper,
  TextWithPicWrapper,
  TextArea,
  PicArea,
  Heading,
  Text,
  Pic,
} from "../styles/AboutUs.Styles";
import AboutPic from "../assets/all.png";
import Navbar from "./Navbar";
import Footer from "./Footer";

const AboutUs = () => {
  return (
    <MainWrapper>
      <Navbar />
      <TextWithPicWrapper>
        <TextArea>
          <Heading>ჩვენს შესახებ</Heading>
          <Text>
            2009 წელს მისი უწმიდესობის სქართველოს კათოლიკოს-პატრიარქის ილია
            მეორის ლოვვა-კურთხევითა და წმიდა სინოდის გადაწყვეტილებით შეიქმნა
            ჩრდილოეთ ამერიკის ეპარქია. რომლის მართველად დადგინდა ბათუმისა და
            ლაზეთის მიტროპოლიტი დიმიტრი. რომლის ლოცვა-კურთხევით დაიწყო სხვადახვა
            შტატებში ქართული სამრევლოების შექმნა., რომელთა შორის ერთერთი
            პირველთაგანი იყო ბრუკლინის სამრევლო. 2013 წლის 28 იანვარს, საეპარქიო
            კრებაზე განხილულ იქნა მისი მაღალყოვლადუსამღვდელოესობის ,მეუფე
            დიმიტრისადმი ნიუ-იორკის შტატის ქართული დიასპორის წერილობით
            თხოვნა-მიმართვა, რომელსაც ხელს აწერდა ასობით ოჯახი. ისინი ითხოვდნენ,
            რომ ნიუ იორკში, კერძოდ ქალაქ ბრუკლინში გახსნილიყო ქართული ეკლესია,
            რომელიც საქართველოს საპატრიარქოს იურისდიქციაში იქნებოდა. ამავე
            კრებაზე მსჯელობის შემდეგ გადაწყდა, რომ ბრუკლინში დაარსებულიყო
            ყოვლადწმიდა ღვთისმშობლის შობის სახელობის სამრევლო და მის წინამძღვრად
            დადგინებულ იქნა პენსილვანიის შტატში მოღვაწე არქიმანდრიტი საბა
            (ინწკირველი), დღეს უკვე ეპარქიის მართველი. მრევლის მატებამ გამოიწვია
            საჭიროება მუდმივად ყოფილიყო სასულიერო პირი სამრევლოში, რომელიც
            ყოველდღიურად მოემსახურებოდა მრევლს.ბათუმის, ლაზეთისა და ჩრდილოეთ
            ამერიკის მიტროპოლიტმა დიმიტრიმ (შიოლაშვილი) სამრევლოდან გამოირჩია და
            ღვთისმსახურეად ხელი დაასხა მამა სპირიდონ ნარსიას, ის დიაკვნად აკურთა
            2013 წლის 8 სექტემბერს, ხოლო მღვდლის ხარისხში აიყვანა 2013 წლის, 12
            ოქტომბერს.ასევე , მამა ზაქარია მჭედლიშვილი, რომელიც მეუფე დიმიტრიმ
            დიაკვნად აკურთხა 2012 წლის 5 მაისს , სამღვდელო ხარისხში აიყვანა 2013
            წლის 13 ოქტომბერს და ისიც განაწესებულ იქნა ბრუკლისის ქართულ
            სამრევლოში
          </Text>
        </TextArea>
        <PicArea>
          <Pic src={AboutPic} />
        </PicArea>
      </TextWithPicWrapper>
      <Footer />
    </MainWrapper>
  );
};

export default AboutUs;
