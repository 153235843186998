import React from "react";
import {Link} from 'react-router-dom'
import { FooterWrapper, UniFooterDiv } from "../styles/Footer.Styles";
import { ImLocation } from "react-icons/im";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { BiDonateHeart } from "react-icons/bi";

const Footer = () => {
  return (
    <FooterWrapper>
      <UniFooterDiv
        onClick={(e) =>
          window.open(
            "https://www.google.com/maps/place/Georgian+Orthodox+Church/@40.598428,-73.97875,17z/data=!3m1!4b1!4m5!3m4!1s0x89c244ff1f238d59:0xf72e92fb4ee2dd2c!8m2!3d40.5984236!4d-73.9787691?hl=en-US"
          )
        }
      >
        <ImLocation size={25} />
        170 Avenue T, Brooklyn, NY 11223
      </UniFooterDiv>
      <UniFooterDiv>
        <BsFillTelephoneFill size={25} />
        <a
          style={{ textDecoration: "none", color: "black", margin: "1%" }}
          href="tel:+1-347-417-1741"
        >
          (347) 417-1741
        </a>
      </UniFooterDiv>
      <UniFooterDiv>
        <MdEmail size={25} />
        <a
          style={{ textDecoration: "none", color: "black", margin: "1%" }}
          href="mailto:eklesia.brooklyn@gmail.com"
        >
         Georgian.eklesia@gmail.com
        </a>
      </UniFooterDiv>
      <UniFooterDiv>
        <BiDonateHeart style={{ margin: "1%" }} size={30} />
        <Link to='/Donate' style={{textDecoration:'none', color: 'black'}}>Donate</Link> 
      </UniFooterDiv>
    </FooterWrapper>
  );
};

export default Footer;
