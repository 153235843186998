import styled from "styled-components"


export const FooterWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 15%;
    width: 100%;
    max-height: 125px;

    background: #EFE2BA;
    @media (max-width: 750px) {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 375px){
        height: 20%;
    }

`

export const UniFooterDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35%;
    width: 20%;
    font-size: 1.1em;
    font-weight: bold;
    cursor: pointer;
    color: black;
    text-decoration: none;
    
    @media (max-width: 750px) {
        display: flex;
        font-size: 1em;
        width: 100%;
    }

   

`