import React from "react";
import {
  MainWrapper,
  InfoWrapper,
  ContactArea,
  UniContactDiv,
} from "../styles/Contact.Styles";
import { ImLocation } from "react-icons/im";
import { BsFillTelephoneFill, BsFacebook } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { BiDonateHeart } from "react-icons/bi";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
const Contact = () => {
  return (
    <MainWrapper>
      <Navbar />
      <InfoWrapper>
        <iframe
          className="googleMap"
          width="50%"
          height="60%"
          style={{ border: "0" }}
          loading="lazy"
          allowFullScreen
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12117.703236984044!2d-73.97875!3d40.598428!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c244ff1f238d59%3A0xf72e92fb4ee2dd2c!2sGeorgian%20Orthodox%20Church!5e0!3m2!1sen!2sus!4v1678650961797!5m2!1sen!2sus"
        ></iframe>
        <ContactArea>
          <UniContactDiv
            onClick={(e) =>
              window.open(
                "https://www.google.com/maps/place/Georgian+Orthodox+Church/@40.598428,-73.97875,17z/data=!3m1!4b1!4m5!3m4!1s0x89c244ff1f238d59:0xf72e92fb4ee2dd2c!8m2!3d40.5984236!4d-73.9787691?hl=en-US"
              )
            }
          >
            <ImLocation size={25} />
            170 Avenue T, Brooklyn, NY 11223
          </UniContactDiv>
          <UniContactDiv>
            <BsFillTelephoneFill size={25} />
            <a
              style={{ textDecoration: "none", color: "gray", margin: "1%" }}
              href="tel:+1-347-417-1741"
            >
              (347) 417-1741
            </a>
          </UniContactDiv>
          <UniContactDiv>
            <MdEmail size={25} />
            <a
              style={{ textDecoration: "none", color: "gray", margin: "1%" }}
              href="mailto:Georgian.eklesia@gmail.com"
            >
              Georgian.eklesia@gmail.com
            </a>
          </UniContactDiv>
          <UniContactDiv
            onClick={(e) =>
              window.open(
                "https://www.facebook.com/%E1%83%A6%E1%83%95%E1%83%97%E1%83%98%E1%83%A1%E1%83%9B%E1%83%A8%E1%83%9D%E1%83%91%E1%83%9A%E1%83%98%E1%83%A1-%E1%83%A8%E1%83%9D%E1%83%91%E1%83%98%E1%83%A1-%E1%83%A1%E1%83%90%E1%83%AE%E1%83%94%E1%83%9A%E1%83%9D%E1%83%91%E1%83%98%E1%83%A1-%E1%83%A5%E1%83%90%E1%83%A0%E1%83%97%E1%83%A3%E1%83%9A%E1%83%98-%E1%83%9B%E1%83%90%E1%83%A0%E1%83%97%E1%83%9A%E1%83%9B%E1%83%90%E1%83%93%E1%83%98%E1%83%93%E1%83%94%E1%83%91%E1%83%94%E1%83%9A%E1%83%98-%E1%83%A1%E1%83%90%E1%83%9B%E1%83%A0%E1%83%94%E1%83%95%E1%83%9A%E1%83%9D-%E1%83%91%E1%83%A0%E1%83%A3%E1%83%99%E1%83%9A%E1%83%98%E1%83%9C%E1%83%A8%E1%83%98-439448212790748"
              )
            }
          >
            <BsFacebook style={{ margin: "1%" }} size={30} />
            Facebook
          </UniContactDiv>
          <UniContactDiv>
            <BiDonateHeart style={{ margin: "1%" }} size={30} />
            <Link
              to="/Donate"
              style={{ textDecoration: "none", color: "gray" }}
            >
              Donate
            </Link>
          </UniContactDiv>
        </ContactArea>
      </InfoWrapper>
      <Footer />
    </MainWrapper>
  );
};

export default Contact;
