import styled from "styled-components";

export const AttentionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vh;
    width: 100vw;
    font-size: 1.4em;
    color: #E54B4B;

    
    animation: AttentionWrapper 5s linear 0s 1 normal forwards;


        @keyframes AttentionWrapper {
        0%,
        50%,
        100% {
            opacity: 1;
        }
    
        25%,
        75% {
            opacity: 0;
        }
    }

    @media(max-width: 750px) {
        height: 15vh;
        font-size: 1.1em;
    }

    
    @media(max-width: 320px) {
        height: 20vh;
        font-size: 0.9em;
    }


`

