import React from "react";
import ImageGallery from "react-image-gallery";
import { MainWrapper, GalleryArea,GalleryBox } from "../styles/Gallery.Styles";
import "react-image-gallery/styles/css/image-gallery.css";
import NavBar from "./Navbar";
import Footer from "./Footer";
import images from "../assets/images";
const Gallery = () => {
  return (
    <MainWrapper>
      <NavBar />
      <GalleryArea >
        <GalleryBox>
        <ImageGallery
          items={images}
          showBullets={true}
          showIndex={true}
          showThumbnails={true}
          lazyLoad={true}
          showPlayButton={true}
          infinite={true}
          thumbnailPosition='left'
          showFullscreenButton={true}
          style={{height: '200px', width: '200px', background: 'red'}}
        />
        </GalleryBox>
      </GalleryArea>
      <Footer />
    </MainWrapper>
  );
};

export default Gallery;
