import tbilisoba from '../data/newsImages/tbilisoba.jpeg';
import amountNov from '../data/newsImages/amountNov.jpg';
import amountDec from '../data/newsImages/amountDec.jpeg';
import amountJan from '../data/newsImages/amountJan2023.jpg';
import amountFeb2023 from '../data/newsImages/amountFeb2023.jpg'
import amountMarch2023 from '../data/newsImages/amountMarch2023.png'
import amountMay2023 from '../data/newsImages/amountMay.png'
import amountAug2023 from  '../data/newsImages/amountAug2023.png'

const newsData = [
    {
        newsTime: "Posted At: 08/17/2023, 09:35:00 PM",
        heading: "დიდება მაღალთა შინა ღმერთსა.",
        img: amountAug2023,
        text: "💓💓💓მადლობა ყველა შემომწირველს ",
    },
    {
        newsTime: "Posted At: 05/31/2023, 10:01:00 AM",
        heading: "დიდება მაღალთა შინა ღმერთსა.",
        img: amountMay2023,
        text: "💓💓💓მადლობა ყველა შემომწირველს ",
    },
    {
        newsTime: "Posted At: 03/12/2023, 04:39:00 AM",
        heading: "🌿🕊🌿🕊🌿 მადლობა ყველას! ",
        img: amountMarch2023,
        text: " ️ ❤️ შეგიწიროთ უფალმა❤️",
    },
    {
        newsTime: "Posted At: 02/14/2023, 11:30:12 AM",
        heading: "🌿🕊🌿ყველა შემომწირველს დიდი მადლობა!!!🌿🕊🌿 ",
        img: amountFeb2023,
        text: " ️💢 ღვთის წყალობითა და თქვენი დახმარებით ძალიან ახლოს ვართ ჩვენს დასახულ მიზანთან. 📣📣📣გთხოვთ,  მონაწილეობა მიიღოთ -  საქართველოს საპატრიარქოსადმი დაქვემდებარებაში მყოფ ტაძრის შეძენაში. 💢💢💢",
    },
    {
        newsTime: "Posted At: 01/04/2023, 10:52:12 AM",
        heading: "ყველა შემომწირველს დიდი მადლობა!!!",
        img: amountJan,
        text: "ღვთის წყალობითა და თქვენი დახმარებით ძალიან ახლოს ვართ ჩვენს დასახულ მიზანთან. გთხოვთ, მონაწილეობა მიიღოთ - საქართველოს საპატრიარქოსადმი დაქვემდებარებაში მყოფ ტაძრის შეძენაში",
    },
    {
        newsTime: "Posted At: 12/13/2022, 09:31:12 AM",
        heading: "ყველა შემომწირველს დიდი მადლობა!!!!",
        img: amountDec,
        text: "ღვთის წყალობითა და თქვენი დახმარებით ძალიან ახლოს ვართ ჩვენს დასახულ მიზანთან. გთხოვთ, მიიღოთ მონაწილეობა საქართველოს საპატრიარქოსადმი დაქვემდებარებაში მყოფ ტაძრის შეძენაში.",
    },
    {
        newsTime: "Posted At: 11/08/2022, 11:31:12 PM",
        heading: "დიდი მადლობა ყველა შემომწირველს!",
        img: amountNov,
        text: " ღვთის წყალობითა და თქვენი დახმარებით ძალიან ახლოს ვართ ჩვენს დასახულ მიზანთან. გთხოვთ, ყველამ მიიღოთ მონაწილეობა საქართველოს საპატრიარქოსადმი დაქვემდებარებაში მყოფ ტაძრის შეძენაში",
    },


    {
        newsTime: "Posted At: 10/08/2022, 22:53:00 PM",
        heading: "TBILISOBA NY 2022",
        img: tbilisoba,
        text: "თბილისობა გაიმართება 2001 Oriental Blvd Brooklyn NY 11235 9 ოქტრომბერს 01:00 საათზე.",
    },


]



export default newsData


