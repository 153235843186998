import { React, useState } from "react";
// import StripeCheckout from "react-stripe-checkout";
import Navbar from "./Navbar";
import Footer from "./Footer";
import {
  MainWrapper,
  CardsWrapper,
  ZelleArea,
  // PaypalArea,
  // StripeArea,
  // NumbersArea,
  // Numbers,
  HeadingZelle,
  ZelleTextArea,
  ZelleScannablePic,
  // HeadingPayPal,
  // PayPalTextArea,
  // PayPalLink,
  // HeadingStripe,
  // StripeTextArea,
  // StripeLink,
} from "../styles/Donate.Styles";
import ZelleScan from "../assets/zellescan.png";
import { BiQrScan } from "react-icons/bi";
// import paypal from "../assets/paypal.png";

const Donate = () => {
  const [product, setProduct] = useState({
    name: "Donate",
    price: 10,
    productBy: "unknown",
  });

  const makePayment = (token) => {
    const body = {
      token,
      product,
    };
    const headers = {
      "Content-type": "application/json",
    };
    return fetch(`http://localhost:3000/donate`, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((res) => {
        console.log("RES", res);
        const { status } = res;
        console.log("STATUS", status);
      })
      .catch((err) => console.log(err));
  };
  return (
    <MainWrapper>
      <Navbar />
      <CardsWrapper>
        <ZelleArea>
          {/* <NumbersArea>
            <Numbers>1</Numbers>
          </NumbersArea> */}
          <HeadingZelle>დაეხმარე ეკლესიას Zelle_ს მეშვეობით</HeadingZelle>
          <ZelleTextArea>
            თქვენ შეგიძლიათ დაეხმაროთ ეკლესიას არსებობაში ზელეს მეშვეობით.
            ქვემოთ მოეცემული ელექტრონული მეილი ან ნომერი შეგიძიათ დაამტოთ თქვენს
            კონტაქტებში Email: georgian.eklesia@gmail.com ან Phone: 646 474-9943
            ასევე შეგიძიათ დაასკანეროთ ქვემოთ მოცემული QR-Code თქვენი ბანკის
            აპლიქაცის მეშვეობით.
          </ZelleTextArea>
          <ZelleScannablePic
            src={ZelleScan}
            // src={ZelleTest}
            // alt="tobedeleted"
          >
            {/* ^^^ need to be changed once we got scannable zelle */}
          </ZelleScannablePic>
        </ZelleArea>
        {/* <PaypalArea>
          <NumbersArea>
            <Numbers>2</Numbers>
          </NumbersArea>
          <HeadingPayPal>დაეხმარე ეკლესიას PayPal_ის მეშვეობით </HeadingPayPal>
          <PayPalTextArea>
            ასევე შეგიძლიათ დაეხმაროთ ეკლესიას არსებობაში PayPal_ის მეშვეობით.
            ქვემოთ მოეცემული PayPal_ის ლოგოზო თითს დაჭერით თქვენ გადახვალთ
            PayPal_ის ოფიციალურ გვერძე საიდანაც შეძლებთ თანხის გადარიცხვას.
          </PayPalTextArea>
          <PayPalLink>
            <img
              src={paypal}
              alt="paypal img"
              height="60%"
              onClick={(event) =>
                window.open(
                  "https://www.paypal.com/paypalme/DavitKhu?country.x=US&locale.x=en_US"
                )
              }
            />
          </PayPalLink>
        </PaypalArea>
        <StripeArea>
          <NumbersArea>
            <Numbers>3</Numbers>
          </NumbersArea>
          <HeadingStripe>დაეხმარე ეკლესიას Card_ის მეშვეობით</HeadingStripe>
          <StripeTextArea>
            ასევე შეგიძლიათ დაეხმაროთ ეკლესიას არსებობაში ბანკის ნებისმიერი
            კარტით. ქვემოთ მოეცემული ღილაკზე თითს დაჭერით გადახვალთ უსაფთხო
            ფანჯარაზე სადაც შეავსებთ მონაცემებს.
          </StripeTextArea>
          <StripeLink>
            <StripeCheckout
              stripeKey={process.env.REACT_APP}
              amount={product.price * 100}
              token={makePayment}
              name="You Are Donating"
            />
          </StripeLink>
        </StripeArea> */}
      </CardsWrapper>
      <Footer />
    </MainWrapper>
  );
};
export default Donate;
