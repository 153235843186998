import React from "react";
import { MainWrapper } from "../styles/Home.Styles";
import Navbar from "./Navbar";
import HomeInfo from "./HomeInfo";
import Footer from "./Footer";
import Temp from "../TempComponent/Temp";

const Home = () => {
  return (
    <>
    {/* <Temp />  component for news*/} 
      <MainWrapper>
        <Navbar />
        <HomeInfo />
        <Footer />
      </MainWrapper>
      </>
  );
};

export default Home;
