import styled from "styled-components"


export const MainWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background: #FFFFFF;

    @media(max-width: 750px) {
        display: flex;
        align-items: flex-end;
    }
`
