import './App.css';
import React from 'react'
import Home from './components/Home';
import AboutUs from './components/AboutUs'
import { Routes, Route } from "react-router-dom";
import Gallery from './components/Gallery';
import MainCalendar from './components/Calendar';
import News from './components/News';
import Donate from './components/Donate';
import Contact from './components/Contact';

function App() {
  return (
    <>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/AboutUs' element={<AboutUs />} />
        <Route path='/Gallery' element={<Gallery />} />
        <Route path='/calendar' element={<MainCalendar />} />
        <Route path='/News' element={<News />} />
        <Route path='/Donate' element={<Donate />} />
        <Route path='/Contact' element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
