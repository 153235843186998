import styled from "styled-components";

export const MainWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background: #FFFFFF;

     @media (max-width: 375px){
        height: auto;
    }
`
export const TextWithPicWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 60%;
    width: 100%;

    @media(max-width: 750px){
        display: flex;
        flex-direction: column;
        height: 70%;
    }


`

export const TextArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 55%;

    max-width: 750px;

    @media(max-width: 750px){
        display: flex;
        width: 100%;
        height: 70%;
    }

`
export const Text = styled.div`
    display: flex;
    font-size: 0.9em;
    color: gray;
    height: 70%;
    width: 90%;


    @media(max-width: 750px){
        display: flex;
        width: 95%;
        height: 85%;
        font-size: 0.65em;
    }

    @media (max-width: 375px){
        margin-top: 5%;
    }
    
`

export const PicArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 40%;

    max-height: 390px;
    max-width: 615px;

    @media(max-width: 750px){
        display: flex;
        height: 40%;
        width: 80%;
    }

    @media (max-width: 375px){
        margin-top: 5%;
        margin-bottom: 5%;
    }

`

export const Pic = styled.img`
    display: flex;
    height: 100%;
    width: 100%;


`

export const Heading = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 90%;
    font-size: 1.8em;
    font-weight: bold;


    @media (max-width: 375px){
        margin-top: 5%;
    }

`