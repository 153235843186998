import styled from "styled-components";

export const MainWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
    width: 100vw;

    // @media (max-width: 375px){

    //     height: 200vh;
    // }


`

export const NewsArea = styled.div`
    display: flex;
    overflow: scroll;
    flex-direction: column;
    height: 64%;
    width: 90%;
    // justify-content: center;
    align-items: center;
    max-height: 850px;
    max-width: 1160px;

    // border-bottom: 1px solid lightgray;
    
    @media (max-width: 750px) {
        display: flex;
        height: 90%;
        width: 98%;
    }
`


export const NewsFeed = styled.div`
    display: flex;
    // margin-top: 10%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90%;
    width: 90%;

    @media (max-width: 750px) {
        display: flex;
        height: 95%;
        width: 98%;
    }

`

export const DateArea = styled.div`
    display: flex;
    font-weight: bold;
    align-items: center;
    height: 5%;
    width: 90%;
    color: gray;
    @media (max-width: 375px){
        // margin-top: 5%;
        font-size: 0.8em;
    }

`

export const Heading = styled.h1`
    display: flex;
    font-size: 1.8em;
    font-wieght: bold;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 90%;

    @media (max-width: 750px){
        font-size: 1.1em;
    }


    @media (max-width: 375px){
        font-size: 1em;
    }

`

export const NewsImage = styled.img`
display: flex;
justify-content: center;
align-items: center;
height: 50%;
width: 50%;
background: lightblue;

// max-height: 300px;
// max-width: 520px;

@media (max-width: 750px) {
    display: flex;
    height: 50%;
    width: 80%;
}



`

export const TextArea = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 30%;
width: 90%;
letter-spacing: 0.1em;
color: gray;

@media (max-width: 750px){
    font-size: 0.9em;
}

@media (max-width: 375px){
    font-size: 0.9em;
}

`