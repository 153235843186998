import styled from "styled-components";

export const MainWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background: #FFFFFF;
`

export const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 90%;
    width: 90%;
    
    @media (max-width: 750px) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .googleMap {
        width: 100%;
    }

`

export const ContactArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 60%;
    width: 50%;
    @media (max-width: 750px) {
        display: flex;
        height: 50%;
        width: 100%;
    }

`

export const UniContactDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 80%;
    font-size: 1.1em;
    font-weight: bold;
    cursor: pointer;
    color: gray;
    text-decoration: none;

`