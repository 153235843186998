import styled from "styled-components";

export const NavWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 10%;
    width: 100%;
    background: #EFE2BA;

`

export const Heading = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 60%;
    font-size: 0.9em;
    font-weight: bold;
    letter-spacing: 0.1em;

`

export const CrossSmall = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 20%;

`

export const Hamburger = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 20%;
    cursor: pointer;

`