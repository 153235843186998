import styled from "styled-components";

export const NavWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 10%;
    width: 100%;
    background: #EFE2BA;

    max-height: 80px;

`

export const UniNavDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35%;
    width: 10%;
    font-size: 1.1em;
    font-weight: bold;
    cursor: pointer;

    :hover{
        font-size: 1.2em;
        border-bottom: 1px solid white;
    }

    animation: UninavDiv 4s ease 0s 1 normal forwards;
    @keyframes UninavDiv {
        0% {
            opacity: 0;
        }
    
        100% {
            opacity: 1;
        }
    }
    @media (hover: hover) {
        font-size: 1.1em; !important
        border-bottom: none;   !important
    }
`

export const CrossArea = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    width: 10%;
    position: relative;

    animation: CrossArea 2s ease 0s 1 normal forwards;

    @keyframes CrossArea {
        0% {
            transform: scale(0);
        }
    
        100% {
            transform: scale(1);
        }
    }
    @media (max-width: 750px) {
        height: 60%;
        width: 5%;
        display: none;  
    }

`