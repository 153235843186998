import styled from "styled-components"

export const MainWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background: #FFFFFF;
`


export const GalleryArea = styled.div`
    display: flex;
    height: 64%;
    width: 90%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`

export const GalleryBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    width: 90%;


`