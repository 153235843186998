import { React, useState } from "react";
import {
  NavWrapper,
  Heading,
  CrossSmall,
  Hamburger,
} from "../styles/HamNavBar.Styles";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { GiIronCross } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import HamLinks from "./HamLinks";
import { Link } from "react-router-dom";

const HamNavBar = () => {
  const [hamburgerIndex, setHamburgerIndex] = useState(0);

  const open = () => {
    setHamburgerIndex((prevIndex) => prevIndex + 1);
  };

  const close = () => {
    setHamburgerIndex((prevIndex) => prevIndex - 1);
  };


  return (
    <>
      <NavWrapper>
        <CrossSmall>
          <GiIronCross size={50} />
        </CrossSmall>
        <Heading>
          <Link to="/" style={{ textDecoration: "none", color: "black" }}>
            ღვთისმშობლის შობის სახელობის ქართული ეკლესია
          </Link>
        </Heading>
        <Hamburger>
          {hamburgerIndex === 0 ? (
            <AiOutlineMenuUnfold size={50} onClick={open} />
          ) : (
            <GrClose size={50} onClick={close} />
          )}
        </Hamburger>
      </NavWrapper>
      {hamburgerIndex === 1 ? <HamLinks /> : null}
    </>
  );
};

export default HamNavBar;
