import { React, useState } from "react";
import { MainWrapper, MainImg, ArrowArea } from "../styles/HomeInfo.Style";
import MainPic from "../assets/mtredi.png";
import saviour from '../assets/saviour.png'
import image1 from "../assets/mtredi1.jpeg";
import image2 from "../assets/mtredi.png";
import image3 from "../assets/behind.png";
import image4 from "../assets/everybody.jpeg";
import SaintMarry from "../assets/saintmarry.png";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

const images = [SaintMarry, image1, image2, image3, image4];

const HomeInfo = () => {
  const [imageIndex, setImageIndex] = useState(0);

  const nextPic = () => {
    setImageIndex((prevIndex) => prevIndex + 1);
  };

  const prevPic = () => {
    setImageIndex((prevIndex) => prevIndex - 1);
  };

  return (
    <MainWrapper>
      <ArrowArea>
        {imageIndex === 0 ? (
          <div></div>
        ) : (
          <IoIosArrowBack size={100} onClick={prevPic} />
        )}
      </ArrowArea>
      <MainImg src={images[imageIndex]} />
      <ArrowArea>
        {imageIndex !== images.length - 1 && (
          <IoIosArrowForward size={100} onClick={nextPic} />
        )}
      </ArrowArea>
    </MainWrapper>
  );
};

export default HomeInfo;
