import styled from "styled-components"


export const MainWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 3%;
    height: 60%;
    width: 100%;
    
`

export const MainImg = styled.img`
    display: flex;
    height: 100%;
    border-radius: 10%;

    max-height: 600px;
    max-width: 1050px;

    @media (max-width: 750px) {
        display: flex;
        height: 70%;
        width: 80%;
    }

`

export const ArrowArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 8%;
    cursor: pointer;

    :hover{
        color: #EFE2BA;
        border-radius: 80px;
    }
    


`